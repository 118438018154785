// extracted by mini-css-extract-plugin
export var qaAchievementsSection = "s_cG";
export var qaBenefitsSection = "s_ct";
export var qaBusinessSection = "s_cv";
export var qaCtaSection = "s_cB";
export var qaCtaSection__cta = "s_cC";
export var qaDomainExpertiseSection = "s_cy";
export var qaProjLogoSection = "s_cF";
export var qaResultSection = "s_cx";
export var qaServicesSection = "s_cq";
export var qaTechStackSection = "s_cz";
export var qaTestimonialPost = "s_cw";
export var qaTestingServicesSection = "s_cr";
export var qaVideoClientSection = "s_cH";
export var qaWhenDoYouNeedSection = "s_cs";
export var qaWhyChooseSection = "s_cD";