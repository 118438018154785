import { getImage } from 'gatsby-plugin-image'

import CompanyWhyHireBlock from '~/components/blocks/CompanyWhyHireBlock'
import FaqSection from '~/components/blocks/FAQ'
import HeadSection from '~/components/blocks/HeadSection'
import Testimonial from '~/components/blocks/TestimonialPost'
import CallToAction from '~/components/call-to-actions/CallToAction'
import Service3DIconCard from '~/components/cards/Service3DIconCard'
import ServicesIconSmallCard from '~/components/cards/ServicesIconSmallCard'
import ServicesIndustriesCard from '~/components/cards/ServicesIndustriesCard'
import ClientVideoQuotes from '~/components/items/ClientVideoQuotes'
import MainLayout from '~/components/layouts/MainLayout'
import PrimeContactFormSection from '~/components/sections/PrimeContactFormSection'
import PrimeSectionServices from '~/components/sections/PrimeSectionServices'
import LazyHydrate from '~/components/shared/LazyHydrate'
import SubtractBlock from '~/components/shared/SubtractBlock'
import StickyNavigation from '~/components/sticky/StickyNavigation'
import DevopsWhyChoose from '~/mock/services/devops/DevopsWhyChoose'
import Anchors from '~/mock/services/quality-assurance/Anchors'
import QABenefits from '~/mock/services/quality-assurance/QABenefits'
import QABusiness from '~/mock/services/quality-assurance/QABusiness'
import QADevExpertise from '~/mock/services/quality-assurance/QADevExpertise'
import QAFAQ from '~/mock/services/quality-assurance/QAFAQ'
import QAIndustries from '~/mock/services/quality-assurance/QAIndustries'
import QAServices from '~/mock/services/quality-assurance/QAServices'
import QAWhenDoYouNeed from '~/mock/services/quality-assurance/QAWhenDoYouNeed'
import { fileToImageLikeData } from '~/utils'
import CompanyProjectsLogoSection from '~/views/Homepage/components/CompanyProjectsLogoSection'
import AwardsServicesSection from '~/views/Services/components/AwardsServicesSection'
import ServicesSectionBeforeTestimonials from '~/views/Services/components/ServicesSectionBeforeTestimonials'
import ServicesTechStackSectionQA from '~/views/Services/components/ServicesTechStackSectionQA'
import useQualityAssuranceStaticQuery from '~/views/Services/QualityAssurance/useQualityAssuranceStaticQuery'

import ServicesDevExpertiseCompany from '../components/ServicesDevExpertiseCompany'

import * as containerStyles from './QualityAssurance.module.scss'

const ServicesQualityAssurance = () => {
  const query = useQualityAssuranceStaticQuery()

  const tailoredOne = getImage(fileToImageLikeData(query.tailoredOne))
  const tailoredTwo = getImage(fileToImageLikeData(query.tailoredTwo))
  const videoReviewImage = getImage(fileToImageLikeData(query.videoReviewImage))
  const clientRef = getImage(fileToImageLikeData(query.clientRef))
  const quoteCodicaOleksiiPhoto = getImage(
    fileToImageLikeData(query.quoteCodicaOleksiiPhoto),
  )

  return (
    <MainLayout scrollTop>
      <PrimeSectionServices
        titleTop="Quality Assurance"
        title="Services"
        description="Quality is never an accident. Our quality assurance specialists provide superb QA testing services through a profound understanding of your business needs."
        breadcrumbSecondLevel="Services"
        breadcrumbSecondLevelPath="/services/"
        breadcrumbCurrent="Quality Assurance Services"
        breadcrumbCurrentPath="/services/quality-assurance/"
      />

      <StickyNavigation anchors={Anchors} />

      <section className={containerStyles.qaServicesSection} id="services">
        <HeadSection
          position="center"
          title="Our QA services"
          description="Hiring a professional QA services team will enable you to reduce costs and increase your solution's efficiencies by adjusting the focus on what needs to be enhanced."
          descriptionLarge
        />
        <Service3DIconCard dataContent={QAServices} />
      </section>

      <section className={containerStyles.qaTestingServicesSection}>
        <HeadSection
          position="center"
          title="Quality assurance testing services"
          description="With our QA services each part of the system will provide quality and perform well."
          descriptionLarge
        />
        <ServicesSectionBeforeTestimonials
          imageOne={tailoredOne}
          imageTwo={tailoredTwo}
          alignItems="align-items-center"
          content={[
            {
              text: 'Software testing is one of the essential stages in the web development life cycle. It helps to control quality software techniques. Generally, software testing is a process of checking the functionality created in terms of specific requirements.',
            },
            {
              text: 'Quality assurance experts write effective test cases and look for defects in the web product at the early stages. This helps to prevent future cost losses and the system from falling.',
            },
            {
              text: 'Codica’s software testing services ensure that each of your web app’s functions works according to its behavioral requirements. Each part of the system should provide quality and perform well.',
            },
            {
              text: 'Our superior software quality assurance services reduce development costs. They also help to increase the speed, security, quality, and customer experience of the solution.',
            },
          ]}
        />
      </section>

      <section
        className={containerStyles.qaWhenDoYouNeedSection}
        id="use-cases"
      >
        <HeadSection position="center" title="When do you need QA?" />
        <ServicesIconSmallCard isColMd dataContent={QAWhenDoYouNeed} />
      </section>

      <section className={containerStyles.qaBenefitsSection} id="benefits">
        <HeadSection
          position="center"
          title="Top-notch benefits of our QA services"
          description="We use advanced QA practices to help our clients deliver better software products."
          titleLarge
          descriptionLarge
        />
        <ServicesIconSmallCard isBorder dataContent={QABenefits} />
      </section>

      <section className={containerStyles.qaBusinessSection} id="business">
        <HeadSection
          position="center"
          title="We will help boost your business:"
        />
        <ServicesIconSmallCard isColSm dataContent={QABusiness} />
        <SubtractBlock />
      </section>

      <div className={containerStyles.qaTestimonialPost}>
        <Testimonial
          image={quoteCodicaOleksiiPhoto}
          name="Oleksii"
          position="QA Team Lead at Codica"
          quote={[
            {
              text: '“QA as a Service is the last line between an application and the real world. When it comes to software testing services, solely "working" is not sufficient nowadays - web applications should perform well under specific loads, run fine on most modern devices, remain secure against constantly updating vulnerability knowledge base, and more.',
            },
            {
              text: 'From project to project, we usually meet new requirements and challenges. So, we adopt approaches and technologies to keep pace with the time. We also advance our test automation practices along with the manual, thus winning more time to increase coverage of software testing.”',
            },
          ]}
        />
      </div>

      <CallToAction
        title="Want to get a reliable app?"
        subtitle="We would love to help you!"
        classNameForGA="servicesCTA_1"
        linkName="Talk to an expert"
        link="/contacts/"
      />

      <section className={containerStyles.qaResultSection} id="results">
        <HeadSection
          position="center"
          title="Codica is about the quality result and your success"
          description="Over the years, our team of professionals has successfully implemented over 50 high-quality software projects, and the reviews from our clients are proof. By providing effective functionality testing, we ensure that solutions are reliable and well-performing. This is our company's aim - to deliver profitable solutions that drive users and increase the effectiveness of your business."
          descriptionLarge
        />
        <ServicesDevExpertiseCompany dataContent={QADevExpertise} />
      </section>

      <section
        className={containerStyles.qaDomainExpertiseSection}
        id="industries"
      >
        <HeadSection
          position="center"
          title="Domain expertise you can rely on"
          description="Building web projects for particular industries has allowed our team to grow in-house expertise which we share with our customers. We provide consultations on every stage of the web app development process and build products following the best industry standards."
          descriptionLarge
        />
        <ServicesIndustriesCard dataContent={QAIndustries} />
      </section>

      <section className={containerStyles.qaTechStackSection} id="tech-stack">
        <HeadSection
          position="center"
          title="Our proven tech stack for software quality assurance"
          description="Codica’s quality assurance services will assist you in establishing control over your project’s life cycle. So you will be able to monitor each development stage and receive exact project quality information. For over 9+, our QA testing experts have been using the most effective technologies for functionality testing and the latest practices to build high-quality web solutions."
          descriptionLarge
        />
        <ServicesTechStackSectionQA />
      </section>

      <section className={containerStyles.qaCtaSection}>
        <div className="container container-md">
          <ClientVideoQuotes
            name="David Watkins"
            position="Founder of PlanMyKids"
            location="USA"
            image={videoReviewImage}
            quote={[
              {
                text: '“The PlanMyKids journey started when my family struggled to plan our own kid’s activities. As busy parents, we were time-constrained, the information we needed was all over the place, and coordinating with family felt like it required a project management certification.”',
              },
            ]}
            link="kids-activities-service-marketplace"
            videoLink="https://www.youtube.com/embed/dzxMCDLghZQ"
          />
        </div>

        <div className={containerStyles.qaCtaSection__cta}>
          <CallToAction
            title="Ready to build quality software?"
            subtitle="Request a free quote."
            classNameForGA="servicesCTA_2"
            linkName="Contact us"
            link="/contacts/"
          />
        </div>
      </section>

      <section className={containerStyles.qaWhyChooseSection} id="testimonials">
        <HeadSection
          position="center"
          title="Why should you build your application with Codica?"
        />
        <CompanyWhyHireBlock dataContent={DevopsWhyChoose} />
      </section>

      <LazyHydrate whenVisible>
        <section className={containerStyles.qaProjLogoSection}>
          <CompanyProjectsLogoSection isCenter />
        </section>
      </LazyHydrate>

      <section className={containerStyles.qaAchievementsSection}>
        <HeadSection
          position="center"
          title="Our achievements"
          description="We are immensely thrilled to develop awesome projects. Independent ratings and research firms recognize our work."
        />
        <AwardsServicesSection />
      </section>

      <section className={containerStyles.qaVideoClientSection}>
        <HeadSection
          position="center"
          title="Our clients say"
          description="Genuine feedback from our clients is precious to us and inspires further progress. We invite you to explore the transformative business experiences of people who entrusted their projects to Codica."
          descriptionLarge
        />
        <div className="container container-md">
          <ClientVideoQuotes
            name="Leon Zinger"
            location="Israel"
            position="Founder at RefDental"
            image={clientRef}
            quote={[
              {
                text: '“What we like most about working with Codica is their deep understanding of our business needs. They quickly grasped our initial idea and delivered our Minimum Viable Product within the specified time and budget. Thanks to this, we could get user feedback fast”',
              },
            ]}
            link="dental-imaging-management-system"
            videoLink="https://www.youtube.com/embed/r1tbx4MEmrY"
          />
        </div>
      </section>

      <section id="free-quote">
        <PrimeContactFormSection
          title="Let’s build innovative software together!"
          formInfoClassName="formInfo__services"
          customSalesInfo
          customSalesInfoStepFour="4. Our expert web app developers will analyze your requirements and suggest the best ways to bring your idea to life."
        />
      </section>

      <section id="faq">
        <FaqSection title="FAQ" dataContent={QAFAQ} />
      </section>
    </MainLayout>
  )
}

export default ServicesQualityAssurance
